import React from 'react'
import nookies, { parseCookies } from 'nookies'
import { firebaseAuth } from './firebaseClient'
import RoleRelation from 'models/role-relation'
import User from 'models/user'
import useUserByEmail from 'hooks/useUserByEmail'
import { useRouter } from 'next/router'
import { Role } from 'consts/role'
import QueryString from 'qs'
import omit from 'lodash/omit'
import { onIdTokenChanged, User as FirebaseUser } from 'firebase/auth'

interface AuthState {
  firebaseUser: FirebaseUser | null
  flowStepUser: User | null
  selectedRole: RoleRelation | null
}

const defaultAuthState: AuthState = {
  firebaseUser: null,
  flowStepUser: null,
  selectedRole: null,
}

const FirebaseAuthContext = React.createContext<{
  authState: AuthState
  dispatchAuthState: React.Dispatch<AuthAction>
  handleSelectRoleAndRedirect: (role?: RoleRelation) => Promise<void>
  handleSelectRole: (role?: RoleRelation) => Promise<void>
  handleRemoveAuth: () => void
}>({
  authState: defaultAuthState,
  dispatchAuthState: () => {},
  handleSelectRoleAndRedirect: (): any => {},
  handleSelectRole: (): any => {},
  handleRemoveAuth: () => {},
})

export enum AuthActionType {
  TokenChange,
  RemoveAuth,
  SetFlowstepUser,
  SetSelectedRole,
}

type AuthAction =
  | {
      type: AuthActionType.TokenChange
      firebaseUser: FirebaseUser
      token: string
    }
  | {
      type: AuthActionType.SetFlowstepUser
      flowStepUser: User | null
    }
  | {
      type: AuthActionType.RemoveAuth
    }
  | {
      type: AuthActionType.SetSelectedRole
      selectedRole: RoleRelation
    }

const FirebaseAuthReducer = (authState: AuthState, authAction: AuthAction) => {
  switch (authAction.type) {
    case AuthActionType.TokenChange: {
      const modifiedAuthState: AuthState = {
        ...authState,
        firebaseUser: authAction.firebaseUser,
      }
      nookies.destroy(null, 'token', {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })
      nookies.set(null, 'token', authAction.token, {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })

      return modifiedAuthState
    }

    case AuthActionType.RemoveAuth: {
      nookies.destroy(null, 'token', {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })
      nookies.destroy(null, 'selectedRole', {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })

      const cookies = document.cookie.split('; ')
      for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.')
        while (d.length > 0) {
          const cookieBase =
            encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path='
          const p = window.location.pathname.split('/')
          document.cookie = cookieBase + '/'
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/')
            p.pop()
          }
          d.shift()
        }
      }

      return defaultAuthState
    }

    case AuthActionType.SetFlowstepUser:
      return {
        ...authState,
        flowStepUser: authAction.flowStepUser,
      }

    case AuthActionType.SetSelectedRole: {
      // Delete partnerClientIds as it can be very large data
      if (authAction?.selectedRole?.partnerClientIds) {
        delete authAction.selectedRole.partnerClientIds
      }
      const stringifiedRole = JSON.stringify(authAction.selectedRole)

      nookies.destroy(null, 'selectedRole', {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })
      nookies.set(null, 'selectedRole', stringifiedRole, {
        path: '/',
        domain:
          process.env.NODE_ENV === 'development'
            ? undefined
            : `.${window?.location?.host}`,
        secure: process.env.NODE_ENV !== 'development',
      })

      return {
        ...authState,
        selectedRole: authAction.selectedRole,
      }
    }

    default:
      return defaultAuthState
  }
}

export const FirebaseAuthProvider = ({ children }: any): JSX.Element => {
  const [authState, dispatchAuthState] = React.useReducer(
    FirebaseAuthReducer,
    defaultAuthState,
  )

  const { data: flowStepUser } = useUserByEmail(
    authState.firebaseUser?.email ?? '',
  )

  React.useEffect(() => {
    if (flowStepUser) {
      dispatchAuthState({
        type: AuthActionType.SetFlowstepUser,
        flowStepUser,
      })
    }
  }, [flowStepUser])

  React.useEffect(() => {
    if (authState.selectedRole) {
      return
    }

    const cookies = parseCookies()

    let selectedRole: any = null

    if (!!cookies?.selectedRole && cookies.selectedRole !== 'undefined') {
      try {
        selectedRole = JSON.parse(cookies.selectedRole)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error })
      }
    }

    dispatchAuthState({
      type: AuthActionType.SetSelectedRole,
      selectedRole,
    })
  }, [authState.selectedRole])

  React.useEffect(() => {
    return onIdTokenChanged(firebaseAuth, async (user) => {
      if (!user) {
        // eslint-disable-next-line no-console
        console.log(`no token found...`)
        dispatchAuthState({ type: AuthActionType.RemoveAuth })

        const cookies = parseCookies()
        if (cookies?.token) {
          window?.location?.reload()
        }

        return
      }

      const token = await user.getIdToken()
      dispatchAuthState({
        type: AuthActionType.TokenChange,
        firebaseUser: user,
        token,
      })
    })
  }, [])

  // force refresh the token every 20 minutes
  React.useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebaseAuth.currentUser
      if (user) {
        await user.getIdToken(true)
        // eslint-disable-next-line no-console
        // console.log(`refreshing token:`, token)
      }
    }, 1000 * 60 * 10)
    return () => clearInterval(handle)
  }, [])

  const router = useRouter()

  const handleSelectRole = React.useCallback(
    async (selectedRole: RoleRelation) => {
      dispatchAuthState({
        type: AuthActionType.SetSelectedRole,
        selectedRole,
      })
    },
    [],
  )

  const handleSelectRoleAndRedirect = async (selectedRole: RoleRelation) => {
    dispatchAuthState({
      type: AuthActionType.SetSelectedRole,
      selectedRole,
    })

    // eslint-disable-next-line no-console
    console.log(`handleSelectRoleAndRedirect`, { selectedRole })

    const next = router.query.next as string | undefined

    // if have next query parameter redirect to the url
    if (next) {
      /**
       * Format the queries to string to handle multiple query parameters
       */
      const queries = QueryString.stringify(omit(router.query, ['next']))
      let nextPath = `${next}`
      if (queries) {
        nextPath = `${next}&${queries}`
      }

      // eslint-disable-next-line no-console
      console.log({ queries, nextPath })

      router.push(nextPath)
      return
    }

    if (selectedRole.role === Role.PlatformAdmin) {
      await router.push('/partners')
      return
    }

    if (selectedRole.role === Role.PartnerAdmin) {
      await router.push(`/partners/${selectedRole.partnerCode}`)
      return
    }

    if (selectedRole.role === Role.PartnerUser) {
      await router.push(`/partners/${selectedRole.partnerCode}`)
      return
    }

    if (
      selectedRole.role === Role.ClientAdmin ||
      selectedRole.role === Role.ClientOwner
    ) {
      await router.push(`/clients/${selectedRole.clientCode}`)
      return
    }

    if (selectedRole.role === Role.ClientUser) {
      await router.push(`/clients/${selectedRole.clientCode}`)
      return
    }
  }

  const handleRemoveAuth = () => {
    dispatchAuthState({ type: AuthActionType.RemoveAuth })
  }

  return (
    <FirebaseAuthContext.Provider
      value={{
        authState,
        dispatchAuthState,
        handleSelectRoleAndRedirect,
        handleSelectRole,
        handleRemoveAuth,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export const useFirebaseAuth = () => {
  return React.useContext(FirebaseAuthContext)
}
