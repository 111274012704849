export enum Role {
  PlatformAdmin = 'platformAdmin',
  PartnerAdmin = 'partnerAdmin',
  PartnerUser = 'partnerUser',
  ClientAdmin = 'clientAdmin',
  ClientUser = 'clientUser',
  ClientOwner = 'clientOwner',
}

export const roleMap: Record<Role, string> = {
  [Role.ClientAdmin]: 'Client Admin',
  [Role.ClientUser]: 'Client User',
  [Role.PartnerAdmin]: 'Partner Admin',
  [Role.PartnerUser]: 'Partner User',
  [Role.PlatformAdmin]: 'Platform Admin',
  [Role.ClientOwner]: 'Client Owner',
}
