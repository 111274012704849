import React, { useEffect } from 'react'

export default function useClickOutside<RefType = any>(
  handler?: (ev: MouseEvent) => any,
) {
  const ref = React.createRef<RefType>()

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !(ref.current as any).contains?.(event.target)) {
        handler?.(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps

  return ref
}
