import { IModalProps } from 'components/Modal'
import React from 'react'

export interface IModalProvider {
  modalContent?: IModalProps
  showModal: (props: IModalProps) => any
  hideModal: () => any
  setIsModalLoading: (flag: boolean) => void
}

const ModalContext = React.createContext<IModalProvider>({
  modalContent: undefined,
  showModal: () => null,
  hideModal: () => null,
  setIsModalLoading: () => {
    // This is intentional
  },
})

export default ModalContext
