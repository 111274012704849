export const APIUrl = process.env.NEXT_PUBLIC_API_URL || ''
export const SearchAPIUrl = process.env.NEXT_PUBLIC_SEARCH_API_URL || ''
export const NotificationAPIUrl =
  process.env.NEXT_PUBLIC_NOTIFICATION_API_URL || ''
export const CommentAPIUrl = process.env.NEXT_PUBLIC_COMMENT_API_URL || ''

export const MockUrl = process.env.NEXT_PUBLIC_MOCK_URL || ''
export const socketUrl = process.env.NEXT_PUBLIC_WS_URL || 'ws://localhost:8080'
export const PaddleAPIBaseUrlProduct = process.env.PADDLE_API_BASE_URL_PRODUCT
export const PaddleAPIBaseUrlCheckout = process.env.PADDLE_API_BASE_URL_CHECKOUT
