import '../styles/index.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import React from 'react'
import { FirebaseAuthProvider } from 'firebase-flowstep/FirebaseAuthProvider'
import { ReactQueryDevtools } from 'react-query/devtools'
import Head from 'next/head'
import ModalProvider from 'contexts/modal/provider'
import NotificationProvider from 'contexts/notification/provider'
import { useHydrateAtoms } from 'jotai/utils'
import { itemsAtom } from 'components/UserFlow/UserFlowBuilder/userFlowTreeAtoms'
import {
  activeApiEnvironmentAtom,
  ActiveEnvironmentVersion,
} from 'components/APIResource/APIEnvironmentVersionSelector/apiEnvironmentVersionProxy'

function MyApp({ Component, pageProps }) {
  const { initialState } = pageProps

  /**
   * Jotai Hydrate
   */
  const initialActiveApiEnvironment: ActiveEnvironmentVersion = {
    selectedApiVersionId: initialState?.prefetchedSelectedApiVersionId ?? '',
    selectedEnvironmentName: initialState?.prefetchedEnvironmentName ?? '',
  }

  useHydrateAtoms(
    initialState
      ? [
          [itemsAtom, initialState?.treeItems ?? []],
          [activeApiEnvironmentAtom, initialActiveApiEnvironment],
        ]
      : [],
  )
  // End Jotai Hydrate

  /**
   * Configurations for react query
   */
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            cacheTime: 1000 * 30,
            staleTime: 1000 * 2,
            refetchOnWindowFocus: false,
            notifyOnChangeProps: 'tracked',
          },
        },
      }),
  )

  return (
    <>
      <Head>
        <title>FlowStep</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <FirebaseAuthProvider>
            <ModalProvider>
              <NotificationProvider>
                <Component {...pageProps} />
              </NotificationProvider>
            </ModalProvider>
          </FirebaseAuthProvider>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
