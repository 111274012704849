import React from 'react'
import { NotificationProps } from './types'

export interface INotificationProvider {
  showSuccessNotification: (props: NotificationProps) => any
  showErrorNotification: (props: NotificationProps) => any
  showNotification: (props: NotificationProps) => any
}

const NotificationContext = React.createContext<INotificationProvider>({
  showSuccessNotification: () => null,
  showErrorNotification: () => null,
  showNotification: () => null,
})

export default NotificationContext
