import React from 'react'
import { IModalProps } from 'components/Modal'
import ModalContext from './context'
import Modal from 'components/Modal'

const ModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [isModalLoading, setIsModalLoading] = React.useState<boolean>(false)
  const [modalContent, setModalContent] = React.useState<
    IModalProps | undefined
  >(undefined)
  const [modalProps, setModalProps] = React.useState<Partial<IModalProps>>({})
  const showModal = React.useCallback(
    ({ onClose, ...props }: IModalProps) => {
      setModalContent({
        ...props,
        onClose: () => {
          setIsModalOpen(false)
          onClose && onClose()
        },
      })
      setIsModalOpen(true)
      setModalProps(props)
    },
    [setIsModalOpen, setModalContent],
  )
  const hideModal = React.useCallback(
    () => setIsModalOpen(false),
    [setIsModalOpen],
  )

  const state = {
    modalContent,
    showModal,
    hideModal,
    setIsModalLoading,
  }
  return (
    <ModalContext.Provider value={state}>
      {children}
      {isModalOpen && (
        <Modal
          isVisible={isModalOpen}
          onClose={modalContent?.onClose}
          title={modalContent?.title}
          content={modalContent?.content}
          footer={modalContent?.footer}
          footerOnLoading={modalContent?.footerOnLoading}
          isLoading={isModalLoading}
          {...modalProps}
        />
      )}
    </ModalContext.Provider>
  )
}

export default ModalProvider
