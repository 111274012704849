import { useQuery } from 'react-query'
import UserService from 'services/user'

const useUserByEmail = (email: string) => {
  return useQuery(
    [`GET USER`, email],
    () => {
      if (!email) {
        return
      }

      return UserService.getUserByEmail(email)
    },
    {
      enabled: !!email,
    },
  )
}

export default useUserByEmail
