import React from 'react'
import { FaTimes } from 'react-icons/fa'
import useClickOutside from 'hooks/useClickOutside'
import ReactDOM from 'react-dom'
import cx from 'classnames'

export interface IModalProps {
  title?: string
  content?: React.ReactNode
  footer?: React.ReactNode
  footerOnLoading?: React.ReactNode
  isVisible?: boolean
  onSave?: () => void
  onCancel?: () => void
  onClose?: () => void
  closeOnClickOutside?: boolean
  width?: string
  modalLayoutStyle?: React.CSSProperties
  overflowY?: 'auto' | 'scroll' | 'visible' | 'hidden'
  showClose?: boolean
  isLoading?: boolean
}

const Modal: React.FC<IModalProps> = ({
  title,
  content,
  footer,
  footerOnLoading,
  isVisible,
  onClose,
  children,
  closeOnClickOutside = true,
  width,
  modalLayoutStyle,
  overflowY,
  showClose = true,
  isLoading = false,
}) => {
  const modalRef = useClickOutside(
    () => closeOnClickOutside && !isLoading && onClose?.(),
  )

  if (!isVisible) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={`fixed z-40 w-screen h-screen top-0 left-0`}>
      <div
        className="relative justify-center w-full h-full"
        style={{ backgroundColor: 'rgba(107, 114, 128, 0.75)' }}
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          className={cx(
            'absolute w-3/4 max-w-sm md:max-w-2xl inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 max-h-3/4',
            {
              'overflow-y-auto': !overflowY || overflowY === 'auto',
              'overflow-y-visible': overflowY === 'visible',
              'overflow-y-hidden': overflowY === 'hidden',
              'overflow-y-scroll': overflowY === 'scroll',
            },
          )}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: width,
            ...modalLayoutStyle,
          }}
          ref={modalRef}
        >
          <div className="flex items-center justify-between">
            <h4 className="m-0">{title}</h4>
            {showClose ? (
              <div className="hidden sm:block">
                <button
                  className="transition duration-150 ease-in-out text-flowStep40 hover:text-flowStep60 focus:outline-none focus:text-flowStep60"
                  type="button"
                  onClick={() => {
                    if (!isLoading) {
                      onClose?.()
                    }
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ) : null}
          </div>

          <div className="sm:items-start">
            <div className="mt-3 text-left sm:mt-0">
              {isVisible && <div className="mt-2">{content || children}</div>}
            </div>
          </div>
          {!!footer && !isLoading ? (
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              {footer}
            </div>
          ) : null}
          {isLoading && (
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              {footerOnLoading}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default Modal
