import React from 'react'

import NotificationContext from './context'

import rcNotification from 'rc-notification'
import { RiCloseFill, RiErrorWarningLine, RiCheckLine } from 'react-icons/ri'

export interface Props {
  content: React.ReactNode
  isError?: boolean
  isSuccess?: boolean
  duration?: number
  [prop: string]: any
}

export interface INotification {
  showNotification: (props: Props) => any
}

export const Notification = (): INotification => {
  const showNotification = ({
    isError,
    isSuccess,
    content,
    duration = 2,
    ...rest
  }: Props) => {
    rcNotification.newInstance(
      {
        className: 'fixed max-w-md w-full z-50',
        style: { top: 65 },
      },
      (instance) => {
        const key = Date.now()
        const closeNotification = (key) => {
          instance.removeNotice(key)
        }
        instance.notice({
          ...rest,
          key,
          closable: false,
          duration,
          content: (
            <div className="w-full flex justify-end px-4">
              <div className="max-w-sm w-full bg-white shadow-md rounded-lg pointer-events-auto mb-4 ">
                <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {isSuccess && (
                          <RiCheckLine className="h-6 w-6 text-flowOutcome" />
                        )}
                        {isError && (
                          <RiErrorWarningLine className="h-6 w-6 text-flowAction" />
                        )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm leading-5 font-medium text-flowStep80 flex flex-col">
                          {content}
                        </p>
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        <button
                          onClick={closeNotification.bind(null, key)}
                          className="inline-flex text-flowStep40 focus:outline-none focus:text-flowStep60 transition ease-in-out duration-150"
                        >
                          <RiCloseFill size="1.5em" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        })
      },
    )
  }
  return {
    showNotification,
  }
}

const NotificationProvider: React.FC = ({ children }) => {
  const showErrorNotification = (props) => {
    Notification().showNotification({
      ...props,
      isError: true,
    })
  }
  const showSuccessNotification = (props) => {
    Notification().showNotification({
      ...props,
      isSuccess: true,
    })
  }
  const showNotification = (props) => {
    Notification().showNotification({
      ...props,
      isError: false,
      isSuccess: false,
    })
  }

  return (
    <NotificationContext.Provider
      value={{
        showErrorNotification,
        showSuccessNotification,
        showNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
