import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, browserLocalPersistence, initializeAuth } from 'firebase/auth'

const apiKey = process.env['NEXT_PUBLIC_FIREBASE_API_KEY']
const authDomain = process.env['NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN']
const projectId = process.env['NEXT_PUBLIC_FIREBASE_PROJECT_ID']
const appId = process.env['NEXT_PUBLIC_FIREBASE_APP_ID']

const CLIENT_CONFIG = {
  apiKey,
  authDomain,
  projectId,
  appId,
}

let firebaseClient: FirebaseApp | undefined
let firebaseAuth: Auth

if (typeof window !== 'undefined' && !firebaseClient) {
  firebaseClient = initializeApp(CLIENT_CONFIG)
  firebaseAuth = initializeAuth(firebaseClient, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: undefined,
  })
}

export { firebaseClient, firebaseAuth }
