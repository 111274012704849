import Axios, { AxiosInstance } from 'axios'
import nookies from 'nookies'

export interface IHttpService {
  readonly instance: AxiosInstance
}

const _instance = Axios.create()
_instance.interceptors.request.use((config) => {
  const cookies = nookies.get()
  const token = cookies.token

  /**
   * The token here only for browser context
   * to get token on SSR need to use nookies(context)
   * on the getServerSideProps
   */
  if (!token || typeof window === 'undefined') {
    return config
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

const HttpService: IHttpService = {
  get instance() {
    return _instance
  },
}

export default HttpService
