import { AxiosRequestConfig } from 'axios'
import { APIUrl } from 'consts/endpoints'
import { Role } from 'consts/role'
import omit from 'lodash/omit'
import ListQueryParams from 'models/list-query-params'
import ListQueryResponse from 'models/list-query-response'
import { ForgotPassword, ResetPassword } from 'models/authentication'
import HttpService from './http'
import User from 'models/user'

interface GetUserParams extends ListQueryParams {
  keyword?: string
  roles?: string
  isActive?: boolean
}

const UserService = {
  createUser: async (data: Partial<User>, options?: AxiosRequestConfig) => {
    const res = await HttpService.instance.post<User>(
      `${APIUrl}/v1/users`,
      data,
      options,
    )
    return res.data
  },
  updateUser: async (data: Partial<User>, options?: AxiosRequestConfig) => {
    if (data.userId) {
      const res = await HttpService.instance.put<User>(
        `${APIUrl}/v1/users/${data.userId}`,
        omit(data, ['id']),
        options,
      )
      return res.data
    } else {
      const res = await HttpService.instance.post<User>(
        `${APIUrl}/v1/users`,
        omit(data, ['id']),
        options,
      )
      return res.data
    }
  },
  async getList(
    partnerId?: string,
    clientId?: string,
    params?: GetUserParams,
    options?: AxiosRequestConfig,
  ): Promise<ListQueryResponse<User>> {
    const { data } = await HttpService.instance.get<ListQueryResponse<User>>(
      `${APIUrl}/v1/users`,
      {
        ...options,
        params: {
          ...options?.params,
          ...params,
          partnerId,
          clientId,
          roles: params?.roles,
        },
      },
    )

    return data
  },
  getClientUsers(
    clientId: string,
    params?: GetUserParams,
    options?: AxiosRequestConfig,
  ): Promise<ListQueryResponse<User>> {
    const paramsWrapper: GetUserParams = {
      ...params,
      roles: [Role.ClientUser, Role.ClientAdmin, Role.ClientOwner].join(','),
    }

    return this.getList(undefined, clientId, paramsWrapper, options)
  },
  getPartnerUsers(
    partnerId: string,
    params?: GetUserParams,
    options?: AxiosRequestConfig,
  ): Promise<ListQueryResponse<User>> {
    const paramsWrapper: GetUserParams = {
      ...params,
      roles: [Role.PartnerAdmin, Role.PartnerUser].join(','),
    }

    return this.getList(partnerId, undefined, paramsWrapper, options)
  },
  getUserById: async (id: string, options?: AxiosRequestConfig) => {
    const res = await HttpService.instance.get<User>(
      `${APIUrl}/v1/users/${id}`,
      options,
    )
    return res.data
  },
  getUserByEmail: async (email: string, options?: AxiosRequestConfig) => {
    const res = await HttpService.instance.get<User>(
      `${APIUrl}/v1/users/email/${encodeURIComponent(email)}`,
      options,
    )
    return res.data
  },
  resetPassword: async (payload: ResetPassword) => {
    const res = await HttpService.instance.post<{
      clientCode?: string
      email?: string
    }>(`${APIUrl}/v1/users/resetpassword`, payload)

    return res.data
  },
  forgotPassword: (payload: ForgotPassword) => {
    return HttpService.instance
      .put(`${APIUrl}/v1/users/forgotpassword`, payload)
      .then((res) => res.data)
  },
}

export default UserService
