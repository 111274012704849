import { atom } from 'jotai'

export type ActiveEnvironmentVersion = {
  selectedEnvironmentName: string
  selectedApiVersionId: string
}

export const rootParentId = '00000000-0000-0000-0000-000000000000'

export const activeApiEnvironmentAtom = atom<ActiveEnvironmentVersion>({
  selectedApiVersionId: '',
  selectedEnvironmentName: '',
})
